import { Box } from "@mui/system";
import PDF from './Downloadables/Jasjyot_CV.pdf';
import ME from './Downloadables/jas.png';
import './terminal_input.css';
import { useState,useEffect } from "react";


/*
List of comannds till now that have been added
1) echo
2) clear
3) whoami
4) resume
8) social
    5) linkedin
    6) github
    7) youtube
9) Secret
10) Projects

*/


export default function TERMINAL_INPUT({stateValue}){

    const [enteredCommand,setEntereCommandd]= useState('');
    const [todiable,setTodiable]= useState(stateValue);
    const [OUTPUT,setOUTPUT] = useState('');
    const [matches, setMatches] = useState(
      window.matchMedia("(min-width: 768px)").matches
    )
  
    useEffect(() => {
      window
      .matchMedia("(min-width: 768px)")
      .addEventListener('change', e => setMatches( e.matches ));
    }, []);

    const onChange = (event) => {
        setEntereCommandd(event.target.value);
      };
    

      const handleSubmit =(event) => {
        event.preventDefault();
        setTodiable(true);
        commandOutput(enteredCommand.trim());
        

      };

      const commandOutput = (cmd) => {
        if(cmd==='jas'){
          setOUTPUT(
            <>
            WELCOME Back Master, How may I serve you?
            <TERMINAL_INPUT stateValue={false} />
            </>
             
          );
        }

        //ECHO COMMND
        else if(cmd.startsWith("Echo") || cmd.startsWith('echo') || cmd.startsWith('ECHO') || cmd==='5'){
          if(cmd.startsWith('Echo ') || cmd.startsWith('echo ') || cmd.startsWith('ECHO ')){
            setOUTPUT(
              <>
              Jas said, {cmd.slice(5)}
              <TERMINAL_INPUT stateValue={false} />
              </>
            );
          }
          else{
            setOUTPUT(
              <>
              syntax: echo [Whatever you want jas to say]
              <TERMINAL_INPUT stateValue={false} />
              </>
            );
          }
          
        }
        //CLEAR COMMAND
        else if(cmd.toLowerCase() ==='clear'){
          window.location.reload(false);

        }
        
        //whoami COMMAND
        else if(cmd.toLowerCase() ==='whoami' || cmd==='1'){
          console.log(matches)
          if(matches){
            setOUTPUT(
              <>
              <div className="flex-container">
                <div className="flex-item-left">
              <p>
                Hello There! <br />
                My Name is Jasjyot Singh Saini, or you can call me <b>JAS</b>. I am a Final year engineering student from Mumbai, India.
                There are 2 things that make me ME, <b>Computer Science</b> and <b>Football</b>. Specifically, I have a passion for Cyber Security which is 
                essentially breaking things or preventing others from doing the same.<br />
                My ambition is to make it big in this field, I hope to create a <b>positive difference</b> wherever I go. 
                Through my projects and Internship experiences I hope to show my hunger for <b>knowledge</b> and the <b>quality</b> of work I strive to deliver.
                <br />
                Other than CS, I am a huge <b>Chelsea</b> fan (Up the Chels) and love to make <b>terrible</b> jokes :D
              </p>
              </div>
              <div className="flex-item-right">
              {matches && (<img id="PC" src={ME} alt="my only good pic is also not loading" />)}
            </div>
            </div> 
            <TERMINAL_INPUT stateValue={false} />
            </>
            );
          }
          else{
            setOUTPUT(
              <>
              <p>
                Hello There! <br />
                My Name is Jasjyot Singh Saini, or you can call me <b>JAS</b>. I am a Final year engineering student from Mumbai, India.
                There are 2 things that make me ME, <b>Computer Science</b> and <b>Football</b>. Specifically, I have a passion for Cyber Security which is 
                essentially breaking things or preventing others from doing the same.<br />
                My ambition is to make it big in this field and hope to create a <b>positive difference</b> wherever I go. 
                Through my work and internship experiences I hope to show my hunger for <b>knowledge</b> and the <b>quality</b> of work I strive to deliver.
                <br />
                Other than CS, I am a huge <b>Chelsea</b> fan (Up the Chels) and love to make <b>terrible</b> jokes :D
              </p>
              <div id="l">
              <img id="Mobile" src={ME} alt="my only good pic is also not loading :(" />
              
              </div>
              <br />
              <TERMINAL_INPUT stateValue={false} />
              </>

            );
          }
          
        }

        //secret COMMAND
        else if(cmd.toLowerCase()==='secret' || cmd==='6'){
          setOUTPUT(
            <>
            There is a Flag hidden somewhere with format 'CTF[...]'!<br />
            Find it and and enter it through the terminal.
            <TERMINAL_INPUT stateValue={false} />
            </>
          );
        }
        else if(cmd.toLowerCase() === 'ctf[yoooooooooooooo]'){
          setOUTPUT(
            <>
            We got a CTF player with us :D <br />
            *does the secret cool handshake*
            <TERMINAL_INPUT stateValue={false} />
            </>

          );
        }

        //Projects Command
        else if(cmd.toLowerCase()==='projects' || cmd.toLowerCase()==='project'|| cmd==='4'){
          setOUTPUT(
            <>
            Public Projects In progress... The projects which i have done are closed source!
            <TERMINAL_INPUT stateValue={false} />
            </>
          );

        }

        //CONTACT ME CoMMAND
        else if (cmd.toLowerCase()==='socials' || cmd.toLowerCase()==='social' || cmd==='2'){
          setOUTPUT(
            <>
            <br />
            Email: <b><a href="mailto:definitelynot.jas@gmail.com">definitelynot.jas@gmail.com</a></b>  <br />
            
            <br />
            Or type <b>linkedin</b>,<b> github</b> & <b>youtube</b> in the terminal to connect! <br />
            
            
            <TERMINAL_INPUT stateValue={false} />
            </>
          );
        }
        // SUB COMMANDS
        //Linkedin
        else if(cmd.toLowerCase()==="linkedin" || cmd==='8'){
          window.open("https://www.linkedin.com/in/jasjyotsaini/", '_blank');
          setOUTPUT(
            <>
            Aha!
            <TERMINAL_INPUT stateValue={false} />
            </>
          );
        }

        //Github
        else if(cmd.toLowerCase()==="github" || cmd==='9'){
          window.open("https://github.com/JAS-JPG", '_blank');
          setOUTPUT(
            <>
            01111001 01101111 00100001
            <TERMINAL_INPUT stateValue={false} />
            </>
          );
        }

        //Youtube
        else if(cmd.toLowerCase()==="youtube" || cmd==='10'){
          window.open("https://www.youtube.com/@jasdoesmediocrelyfunnystuff", '_blank');
          setOUTPUT(
            <>
            ;D
            <TERMINAL_INPUT stateValue={false} />
            </>
          );
        }

        //RESUME DOWNLOAD COMMAND
        else if(cmd.toLowerCase() ==='resume' || cmd==='3'){
          const link = document.createElement('a');
          link.download = "Jasjyot's CV";
          link.href = PDF;
          
          setOUTPUT(
            <>
            Any time now download should begin...<br></br>
            <TERMINAL_INPUT stateValue={false} />
            </>
          );
          link.click();
        }

        //Help Commmand
        else if(cmd.toLowerCase()==='help'){
          setOUTPUT(
            <>
            <br />
            &nbsp;&nbsp;&nbsp;Commands:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Description:<br />
            &nbsp;&nbsp;&nbsp;<b>{"1)"}whoami</b> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Whats a Jasjyot? <br />
            &nbsp;&nbsp;&nbsp;<b>{"2)"}social</b> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;   How to get in touch. <br />
            &nbsp;&nbsp;&nbsp;<b>{"3)"}resume</b> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;My Resume :D  <br />
            &nbsp;&nbsp;&nbsp;<b>{"4)"}projects</b>&nbsp;&nbsp;&nbsp;&nbsp; Projects i have built  <br />
            &nbsp;&nbsp;&nbsp;<b>{"5)"}echo</b> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Make Jas say something!  <br />
            &nbsp;&nbsp;&nbsp;<b>{"6)"}secret</b> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  ???  <br />
            &nbsp;&nbsp;&nbsp;<b>{"7)"}clear</b>  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  Clears screen  <br />
            &nbsp;&nbsp;&nbsp;<b>{"8)"}linkedin</b> &nbsp;&nbsp;&nbsp;&nbsp;Lets C0nnect   <br />
            &nbsp;&nbsp;&nbsp;<b>{"9)"}github</b>   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:D  <br />
            &nbsp;&nbsp;&nbsp;<b>{"10)"}youtube</b>  &nbsp;&nbsp;&nbsp;&nbsp;:0 <br />
            &nbsp;&nbsp;&nbsp;<b>{"11)"}banner</b>  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Display welcome banner <br />
            <br />
            Write the command or the corresponding number in the terminal 
            <br />
            <TERMINAL_INPUT stateValue={false} />
            </>
          );
        }

        //banner comand
        else if(cmd.toLowerCase()==='banner' || cmd==='11'){
          if(matches){
            setOUTPUT(
              <>
              <pre>
              <code>{`
       ██╗ █████╗ ███████╗     ██╗██╗   ██╗ ██████╗ ████████╗
       ██║██╔══██╗██╔════╝     ██║╚██╗ ██╔╝██╔═══██╗╚══██╔══╝
       ██║███████║███████╗     ██║ ╚████╔╝ ██║   ██║   ██║   
  ██   ██║██╔══██║╚════██║██   ██║  ╚██╔╝  ██║   ██║   ██║   
  ╚█████╔╝██║  ██║███████║╚█████╔╝   ██║   ╚██████╔╝   ██║   
   ╚════╝ ╚═╝  ╚═╝╚══════╝ ╚════╝    ╚═╝    ╚═════╝    ╚═╝   
                        (My little conner of the Internet)
  
Type 'help' to see the list of all available commands`}</code>
            </pre>
              <TERMINAL_INPUT stateValue={false} />
              </>
            );
          }
          else{
            setOUTPUT(
              <>
              <pre>
                <code style={{fontFamily: "monospace"}}>
            {`
    

      ┏┳   •       ┏┓•    ┓   ┏┓  •  •
       ┃┏┓┏┓┓┏┏┓╋  ┗┓┓┏┓┏┓┣┓  ┗┓┏┓┓┏┓┓
      ┗┛┗┻┛┃┗┫┗┛┗  ┗┛┗┛┗┗┫┛┗  ┗┛┗┻┗┛┗┗
           ┛ ┛           ┛            

        (My corner of the Internet)
                  `}
                </code>
              </pre>
              Type 'help' to see the list of all available commands
              <TERMINAL_INPUT stateValue={false} />
              </>
            );
          }
          
        }

        //unknown command
        else{
          setOUTPUT(
            <>
            I am sorry I dont know what '{cmd}' means<br />
            type 'help' to see available commands.
            <TERMINAL_INPUT stateValue={false} />
            </>
          );

        }
      }

    return(
        <>

<Box >
<form onSubmit={handleSubmit}>
<div  className="terminal" style={{ overflow: "hidden", whiteSpace: "nowrap"}} >
    <label>guest@jasjyot.xyz:</label><span style={{color: "#12488B"}}>~</span>$
    <input type="text" autoFocus autoComplete="off" value={enteredCommand} onChange={onChange} disabled={todiable} placeholder="type here"/> <br />
    <input type="submit" hidden='true' disabled={todiable} />
    </div>
</form>

{OUTPUT}
</Box>
            </>
    );
}