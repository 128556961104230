import TERMINAL_INPUT from './terminal_input';
import './terminal_input.css';
import { useEffect, useState } from 'react';

 export  default  function App() {

  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 768px)").matches
  )

  useEffect(() => {
    window
    .matchMedia("(min-width: 768px)")
    .addEventListener('change', e => setMatches( e.matches ));
  }, []);

  useEffect(()=>{
    if(matches){
      document.body.style.zoom = "200%";
    }
  },[]);

  return (
    <>
    <div id="bb">
    {matches ? (
      <>
      <pre>
              <code>{`
       ██╗ █████╗ ███████╗     ██╗██╗   ██╗ ██████╗ ████████╗
       ██║██╔══██╗██╔════╝     ██║╚██╗ ██╔╝██╔═══██╗╚══██╔══╝
       ██║███████║███████╗     ██║ ╚████╔╝ ██║   ██║   ██║   
  ██   ██║██╔══██║╚════██║██   ██║  ╚██╔╝  ██║   ██║   ██║   
  ╚█████╔╝██║  ██║███████║╚█████╔╝   ██║   ╚██████╔╝   ██║   
   ╚════╝ ╚═╝  ╚═╝╚══════╝ ╚════╝    ╚═╝    ╚═════╝    ╚═╝   
                        (My little conner of the Internet)
  
Type 'help' to see the list of all available commands`}</code>
            </pre>
      </>
    ):(
      <>
      <pre>
                <code style={{fontFamily: "monospace"}}>
            {`
    
      ┏┳   •       ┏┓•    ┓   ┏┓  •  •
       ┃┏┓┏┓┓┏┏┓╋  ┗┓┓┏┓┏┓┣┓  ┗┓┏┓┓┏┓┓
      ┗┛┗┻┛┃┗┫┗┛┗  ┗┛┗┛┗┗┫┛┗  ┗┛┗┻┗┛┗┗
           ┛ ┛           ┛            

        (My corner of the Internet)
                  `}
                </code>
              </pre>
              Type 'help' to see the list of all available commands
      </>
    )}
    <TERMINAL_INPUT stateValue={false}/>
    </div>
    </>
  );
}


